exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-category-2-microcms-news-category-slug-js": () => import("./../../../src/pages/news/category2/{microcmsNewsCategory.slug}.js" /* webpackChunkName: "component---src-pages-news-category-2-microcms-news-category-slug-js" */),
  "component---src-pages-news-category-js": () => import("./../../../src/pages/news/category.js" /* webpackChunkName: "component---src-pages-news-category-js" */),
  "component---src-pages-news-microcms-news-news-id-js": () => import("./../../../src/pages/news/{microcmsNews.newsId}.js" /* webpackChunkName: "component---src-pages-news-microcms-news-news-id-js" */),
  "component---src-pages-news-more-js": () => import("./../../../src/pages/news-more.js" /* webpackChunkName: "component---src-pages-news-more-js" */),
  "component---src-template-category-js": () => import("./../../../src/template/category.js" /* webpackChunkName: "component---src-template-category-js" */),
  "component---src-template-news-js": () => import("./../../../src/template/news.js" /* webpackChunkName: "component---src-template-news-js" */)
}

